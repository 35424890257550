// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-marketing-js": () => import("./../src/templates/marketing.js" /* webpackChunkName: "component---src-templates-marketing-js" */),
  "component---src-templates-news-js": () => import("./../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-pages-contribute-mdx": () => import("./../src/pages/contribute.mdx" /* webpackChunkName: "component---src-pages-contribute-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-sponsor-mdx": () => import("./../src/pages/sponsor.mdx" /* webpackChunkName: "component---src-pages-sponsor-mdx" */),
  "component---src-pages-postgraphile-index-mdx": () => import("./../src/pages/postgraphile/index.mdx" /* webpackChunkName: "component---src-pages-postgraphile-index-mdx" */),
  "component---src-pages-support-index-mdx": () => import("./../src/pages/support/index.mdx" /* webpackChunkName: "component---src-pages-support-index-mdx" */)
}

